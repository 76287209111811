import { ethers } from "ethers";

let provider;
let signer;
var loggedIn = false;


const connect = async () => {
    // check if metamask is installed
    if (window.ethereum == undefined) {
        alert("metamask extension not found")
    }

    provider = new ethers.providers.Web3Provider(window.ethereum)

    // catch user disconnect and force page reload
    window.ethereum.on("accountsChanged", () => {
        if (loggedIn) {
            window.location.reload()
        }
    });

    await provider.send("eth_requestAccounts", []);
    signer = provider.getSigner();
    let adr = await signer.getAddress();
    loggedIn = true;
    return adr
};

const getBalance = async () => {
    let balance = await signer.getBalance();
    return ethers.utils.formatEther(balance)

}

// const mint = async (tokenID) => {
    
// }

export default { connect, getBalance }
