<template>
  <v-overlay
    class="align-center"
    :value="!hidden"
    :absolute="true"
    :style="style"
    opacity="0"
    @click.native="close()"
  >
    <div v-bind:class="flipped ? 'flip-container flipped' : 'flip-container'">
      <div class="flipper">
        <div class="front">
          <ToneFront
            :name="content.name"
            :code="content.code"
            v-on:flip="flipped = !flipped"
          ></ToneFront>
        </div>
        <div class="back">
          <ToneBack
            :content="content"
            v-on:flip="flipped = !flipped"
          ></ToneBack>
        </div>
      </div>
    </div>
    <img class="hover-shadow" src="@/assets/popup/shadow.png" alt="" />
  </v-overlay>
</template>

<script>
  import ToneFront from "./ToneFront.vue";
  import ToneBack from "./ToneBack.vue";
  import axios from "axios";

  export default {
    name: "ColorInfoModal",
    components: {
      ToneFront,
      ToneBack,
    },
    data() {
      return {
        content: {},
        hidden: true,
        flipped: false,
        currentLightness: 0,
      };
    },
    computed: {
      style: function () {
        let grad = "";

        if (this.currentLightness == 0.5) {
          grad = "rgba(255, 255, 255, 0.3)";
        } else if (this.currentLightness > 0.7) {
          grad = "rgba(166, 166, 166, 0.18)";
        } else if (this.currentLightness > 0.6) {
          grad = "rgba(255, 255, 255, 0.12)";
        } else if (this.currentLightness > 0.41) {
          grad = "rgba(255, 255, 255, 0.1)";
        } else if (this.currentLightness > 0.35) {
          grad = "rgba(255, 255, 255, 0.05)";
        } else {
          grad = "rgba(255, 255, 255, 0.03)";
        }

        return {
          backgroundImage:
            "radial-gradient(50% 50% at center, " +
            grad +
            ", " +
            this.content.code +
            ")",
          "background-color": this.content.code,
        };
      },
    },
    methods: {
      close() {
        this.hidden = true;
        this.$router.push("/");
      },
      parseMetaJson(id, json) {
        let content = {
          special: [],
        };

        content["id"] = id;
        content["name"] = json.name;

        /* eslint-disable no-unused-vars */
        for (const [key, value] of Object.entries(json.attributes)) {
          switch (value["trait_type"]) {
            case "Code":
              content["code"] = value.value;
              break;
            case "Red":
              content["red"] = value.value;
              break;
            case "Blue":
              content["blue"] = value.value;
              break;
            case "Green":
              content["green"] = value.value;
              break;
            case "Colorfamily":
              content["colorFamily"] = value.value;
              break;
            case "Episode II Occurrence":
              content["phase2Frequency"] = value.value;
              break;
            case "Special":
              content["special"].push(value.value);
              break;
            case "Lightness":
              this.currentLightness = value.value;
              break;
          }
        }
        /* eslint-enable no-unused-vars */
        this.content = content;
      },
    },
    watch: {
      $route: {
        handler: function (to) {
          axios
            .get("/json/" + to.params.tokenId + ".json")
            .then((response) => {
              this.parseMetaJson(to.params.tokenId, response.data);
              this.hidden = false;
            })
            .catch(() => {
              this.$router.push("/");
            });
        },
        immediate: true,
        deep: true,
      },
    },
  };
</script>

<style>
#radialBG {
  z-index: 99;
  position: absolute;
}

i.frontFlipBtn,
i.backFlipBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #ffffff;
}
i.backFlipBtn {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.flip-container {
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  -o-perspective: 1000px;
  perspective: 1000px;
}

.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
}
.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}
.back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
}
.flip-container.flipped .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.flip-container.flipped .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.front {
  z-index: 2;
}

.modalSVG {
  width: 150px;
  cursor: pointer;
}

img.hover-shadow {
  position:absolute;
  left:0;
  width: 150px;
  margin-top: 15%;
}

@media (min-width: 600px) {
  .modalSVG {
    width: 300px !important;
  }

  img.hover-shadow {
    width: 300px;
    margin-top: 20%;
  }
}

@media (min-width: 960px) {
  .modalSVG {
    width: 395px !important;
  }

  img.hover-shadow {
    width: 400px;
    margin-top: 20%;
  }
}
</style>