<template>
  <!-- <v-container class="my-10">
    <v-btn
      v-if="this.connected == false"
      rounded
      x-large
      raised
      font-weight-bold
      class="gradient-btn font-weight-bold"
      @click="connect"
    >
      Connect Your Wallet
    </v-btn>
    <v-btn
      v-else
      rounded
      x-large
      raised
      font-weight-bold
      class="gradient-btn font-weight-bold"
      @click="mint"
    >
      Mint Token
    </v-btn>
  </v-container> -->
  <v-container class="layoutContainer d-flex justify-center mb-5 mb--md-13 pb-md-13 pt-0 mt-md-n85" >
    <v-btn
      rounded
      x-large
      raised
      font-weight-bold
      ma-auto
      class="gradient-btn font-weight-bold"
    >
      <a href="http://eepurl.com/hMtvp5" target="_blank">
        Get Exclusive Pre-Access
      </a>
    </v-btn>
  </v-container>
</template>


<script>
  import contract from "../scripts/nftone_contract.js";
  export default {
    name: "ContractButton",
    methods: {
      async connect() {
        var adr = await contract.connect().catch(() => {
          alert("There was an error connecting to your wallet, please try again");
          return;
        });
        if (adr == undefined) {
          return;
        }
        var balance = await contract.getBalance().catch(() => {
          alert("There was an error fetching your balance, please try again");
          return;
        });
        if (balance == undefined) {
          return;
        }
        this.connected = true;
        this.address = adr;
        this.balance = balance;
      },
      async mint() {
        console.log("minting color...");
      },
    },
    data() {
      return {
        connected: false,
        adr: "",
        balance: "",
      };
    },
  };
</script>

<style>
.gradient-btn {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #f1ee08, #23a6d5, #23d5ab);
	background-size: 400% 400%;	
	height: 100vh;
  color: white !important;
  text-decoration: none;
  animation: gradient 2s ease infinite;
  -webkit-animation: AnimationName 2s ease infinite;
  -moz-animation: AnimationName 2s ease infinite;
  animation: AnimationName 2s ease infinite;
}

@keyframes AnimationName {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0%;
	}
}

.gradient-btn  a {
  text-decoration: none !important;
  color: white !important;
}

.mt-n85 {
  margin-top: -85px !important;
}
</style>