<template>
  <v-container class="layoutContainer max-width mt-16">
    <v-row no-gutters>
      <v-col class="col-12 col-sm-6 order-1 order-sm-0 pt-md-16">
        <p class="text-left pt-100">
          Thanks for subscribing, we will keep you up to date! <br>
        </p>
        <p class="text-left pt-5">Return to <router-link to='/'><span class="text-900">NFTONE</span></router-link></p>
        
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="col-12 col-sm-5 order-0 order-sm-2 threeColorContainer">
       <lottie-animation
      ref="anim"
      :animationData="require('@/assets/animation/nftone_header.json')"
        :loop="true"
  :autoPlay="true"
    />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'
  export default {
    name: "Thanks",
    components: {LottieAnimation}
  };
</script>