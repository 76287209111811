<template>
  <div
    class="modalSVG toneBack responsive-font-size"
    @click.stop.prevent="$emit('flip')"
  >
    <div class="conditional-scroll">
      <v-row class="mb-1 mb-md-5 align-end">
        <v-col class="col-6 text-left"
          ><p class="text-900 ma-0 line-height-80 font-size-2em">
            NFTONE
          </p></v-col
        >
        <v-col class="col-6 d-flex justify-end">
          <div
            :style="{ backgroundColor: content.code }"
            class="toneSquare"
          ></div
        ></v-col>
      </v-row>
      <v-row class="text-left alt-row" no-gutters>
        <v-col class="col-6">Token ID</v-col>
        <v-col class="col-6">{{ content.id }}</v-col>
        <v-col class="col-3">Name</v-col>
        <v-col class="col-9">{{ content.name }}</v-col>
        <v-col class="col-6">Hexcode</v-col>
        <v-col class="col-6">{{ content.code }}</v-col>
        <v-col class="col-6">Red</v-col>
        <v-col class="col-6">{{ content.red }}</v-col>
        <v-col class="col-6">Green</v-col>
        <v-col class="col-6">{{ content.green }}</v-col>
        <v-col class="col-6">Blue</v-col>
        <v-col class="col-6">{{ content.blue }}</v-col>
        <v-col class="col-6">Color Family</v-col>
        <v-col class="col-6">{{ content.colorFamily }}</v-col>
        <v-col class="col-6">Phase II Frequency</v-col>
        <v-col class="col-6">{{ content.phase2Frequency }}%</v-col>
        <v-col class="col-6"
          ><p class="ma-0" v-show="showSpecial">Attribute Special</p>
        </v-col>
        <v-col class="col-6 attribute-column" v-show="showSpecial"
          ><p class="ma-0" v-for="item in content.special" :key="item.index">
            {{ item }}
          </p></v-col
        >
      </v-row>
      <v-row class="fixed-font-size text-left mt-2 mt-md-5 no-gutters">
        <v-col class="col-12">
          <p class="my-1 pa-md-0 mt-md-0 text-900">
            <span class="text-900">Current market status</span>
          </p>
          <p class="my-1 pa-md-0">
            This <span class="text-900">NFTONE</span> is not minted yet.
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ToneBack",
    props: ["content"],
    computed: {
      showSpecial: function () {
        return this.content.special.length > 0;
      },
    },

  };
</script>

<style>
.font-size-2em {
  font-size: 2em !important;
}
.attribute-column {
  max-height: 55px;
}
.alt-row {
  font-weight: 700;
  text-align: left;
}

.alt-row .col:nth-child(2n) {
  font-weight: 400;
  text-align: right;
}

.toneSquare {
  width: 12px;
  height: 12px;
}

.line-height-80 {
  line-height: 80%;
}

.toneBack {
  padding: 10px;
  color: black;
  margin: auto;
  height: 207px;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.responsive-font-size {
  font-size: 0.4em !important;
}

.fixed-font-size {
  font-size: 0.7em;
}

@media (min-width: 600px) {
  .toneBack {
    padding: 2em;
    height: 414px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .toneSquare {
    width: 30px;
    height: 30px;
  }

  .responsive-font-size {
    font-size: 0.7em !important;
  }
}

@media (min-width: 960px) {
  .toneBack {
    height: 545px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .responsive-font-size {
    font-size: 1em !important;
  }
}
</style>