<template>
  <v-app>
    <v-main>
      <!-- Header -->
      <Header></Header>
      <router-view></router-view>
      <!-- Footer -->
      <Footer></Footer>
    </v-main>
  </v-app>
</template>

<script>
  import Header from "./components/layout/Header.vue";
  import Footer from "./components/layout/Footer.vue";

  export default {
    components: {
      Footer,
      Header,
    },
  };
</script>

<style lang="scss">
#app {
  background-color: #f4f4f4;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  font-size: 18px;
}

a {
  text-decoration: none !important;
  color: black !important;
}

a.a__black {
  color: black !important;
  text-decoration: underline !important;
}

.text-900 {
  font-weight: 900;
}

.max-width {
  max-width: 1185px !important;
}

.max-width-half {
  max-width: 70% !important;
}

#opensea-logo {
  padding-top: 1px;
  width: 24px;
  height: 24px;
}

#opensea-logo > img {
  width: 100%;
}

.mlr-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>
