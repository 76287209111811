<template>
  <v-container class="max-width">
    <v-row no-gutters class="mt-16 pt-16 align-center">
      <v-col class="col-12 text-center">
        <v-icon id="iconCopyright" color="black" class="pb-1">mdi mdi-copyright</v-icon>
        2022 NFTONE All rights reserved. NFTONES are available under the 
            <a
              href="https://creativecommons.org/licenses/by-sa/3.0/"
              target="_blank"
              class="a__black"
              >CC BY-SA 3.0</a
            >
        
        <!-- <div class="text-caption">
          
          ><span class="ml-1">NFTONE</span>
        </div>
        <div class="text-caption">All rights reserved</div> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "Footer",
  };
</script>