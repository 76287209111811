<template>
  <v-container class="layoutContainer max-width mt-16">
    <v-row no-gutters>
      <v-col class="col-12 col-sm-6 order-1 order-sm-0 pt-md-16">
        <p class="text-left pt-100">
          <span class="font-weight-black">NFTONES</span> (Non Fungible TONES)
          are 10,000 unique colors with a unique name and a unique hex code. So
          no two are exactly alike, and each one of them can be officially owned
          by a single person on the Ethereum blockchain. And some are more
          valuable than others...
        </p>
        <p class="text-left pt-5">Launch date will be announced soon.</p>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="col-12 col-sm-5 order-0 order-sm-2 threeColorContainer">
       <lottie-animation
      ref="anim"
      :animationData="require('@/assets/animation/nftone_header.json')"
        :loop="true"
  :autoPlay="true"
    />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'
  export default {
    name: "Intro",
    components: {LottieAnimation}
  };
</script>

<style type="text/css">
.st0 {
  fill: #d75f5f;
}
.st1 {
  font-family: "Nunito Sans";
  font-weight: 900;
}
.st2 {
  font-size: 59.1684px;
}
.st3 {
  font-family: "Nunito Sans";
  font-weight: 400;
}
.st4 {
  font-size: 42.8667px;
}

@media (min-width: 600px) {
  .pt-100 {
  padding-top: 100px !important;
}
}
</style>