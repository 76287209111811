<template>
  <div>
    <v-container class="layoutContainer max-width mt-md-10">
      <v-row class="mlr-0 no-gutters mt-10 mt-md-16 align-center">
        <v-col class="col-12 col-sm-7 text-left">
          <h2>Phase I - NFTONE Launch</h2>
          <p class="pt-5">
            At the launch date
            <span class="font-weight-black">NFTONES</span> will be sold in four
            phases:
          </p>
          <div>
            <v-row no-gutters>
              <v-col class="col-6 col-md-2">
                <p class="ma-0">early bird:</p>
              </v-col>
              <v-col class="col-6">
                <p class="ma-0">
                  2000 <span class="font-weight-black">NFTONES</span> each for
                  0.04 Ξ
                </p>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row no-gutters>
              <v-col class="col-6 col-md-2">
                <p class="ma-0">fast bird:</p>
              </v-col>
              <v-col class="col-6">
                <p class="ma-0">
                  2500 <span class="font-weight-black">NFTONES</span> each for
                  0.06 Ξ
                </p>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row no-gutters>
              <v-col class="col-6 col-md-2">
                <p class="ma-0">slow bird:</p>
              </v-col>
              <v-col class="col-6">
                <p class="ma-0">
                  2500 <span class="font-weight-black">NFTONES</span> each for
                  0.08 Ξ
                </p>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row no-gutters>
              <v-col class="col-6 col-md-2">
                <p class="ma-0">late bird:</p>
              </v-col>
              <v-col class="col-6">
                <p class="mt-0">
                  2500 <span class="font-weight-black">NFTONES</span> each for
                  0.1 Ξ
                </p>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </div>
          <p>
            In that way we want to reward the early birds and believers.<br />
            500 <span class="font-weight-black">NFTONES</span> will be reserved
            as marketing budget and for the team.
          </p>
        </v-col>
        <v-col class="col-12 mt-5 col-sm-5 justify-center">
          <img
            src="@/assets/episodes/episode1-small.png"
            alt=""
            class="width-100"
          />
        </v-col>
        <!-- <v-col class="col-12 col-md-5 d-none">
          <div class="spline-container2">
            <div id="spline2Watermark"></div>
            <img src="@/assets/episodes/episode1.png" alt="" />
            <iframe
              id="splineFrame"
              src="https://my.spline.design/nftonepanelcopy-ad7299719df5302cf02e3db843680142/"
              frameborder="0"
              width="300px"
              height="300px"
            ></iframe>
          </div>
        </v-col> -->
      </v-row>

      <v-row class="mlr-0 no-gutters mt-10 mt-md-16 align-center">
        <v-overlay :value="overlay" z-index="5" opacity="1" color="black">
          <div>
            <video width="100%" height="100%" autoplay @click="overlay = false">
              <source src="/video/cryptoclassics.mp4" type="video/mp4" />
            </video>
          </div>
        </v-overlay>
        <v-col
          class="
            col-12 col-sm-4
            video-overlay
            d-flex
            justify-center
            align-center
            order-1 order-sm-0
            mt-5
          "
        >
          <img
            src="@/assets/episodes/episode2.png"
            alt=""
            class="width-70 pointer"
            @click="overlay = true"
          />
        </v-col>
        <v-col
          id="section_cryptoClassics"
          class="col-12 col-sm-8 text-left order-0 order-sm-1"
        >
          <h2>Phase II - Crypto Classics</h2>
          <p class="pt-5">
            Earn rewards with your
            <span class="font-weight-black">NFTONES</span>. How? We extracted
            the tones of 100 famous masterpieces which we
            transformed into abstract pixelated versions. Once per week one of
            these rare
            <span @click="overlay = true" class="cc-link">Crypto Classics</span>
            will be auctioned.
          </p>
          <p>
            Owning a <span class="font-weight-black">NFTONE</span> which is part
            of the sold <span class="font-weight-black">Crypto Classic</span>,
            you will get the corresponding percentage of the revenue.
            <br />Owning a
            <span class="font-weight-black">Crypto Classic</span> will give you
            the exclusive right to mint one NFT from the first limited artist
            edition of Phase III for free!<br />
          </p>
          <p>
            It also has another exciting feature, which we will reveal soon.
          </p>
        </v-col>
      </v-row>

      <v-row class="mlr-0 no-gutters mt-10 mt-md-16 align-center">
        <v-col class="col-12 col-sm-8 text-left">
          <h2>Phase III - Artist Collaborations</h2>
          <p class="pt-5">
            We will involve contemporary & new generation artists to collaborate
            with us and create dope NFT art based on the
            <span class="font-weight-black">NFTONE</span> color palette.
          </p>
          <p>
            The first Edition will be exclusive 1000 NFTs of a well known
            contemporary artist.<br />
            50% of the revenue will be donated and 50% go to the artist.<br />More
            will be announced soon!
          </p>
        </v-col>
        <v-col class="col-12 col-sm-4">
          <img src="@/assets/episodes/episode4.png" alt="" class="width-75" />
        </v-col>
      </v-row>

      <v-row class="mlr-0 no-gutters mt-10 mt-md-0 align-center">
        <v-col class="col-12 col-sm-4 order-1 order-sm-0 pt-4 pt-md-0">
          <img
            src="@/assets/episodes/episode3.png"
            alt="Sound Sculptures"
            class="width-75"
          />
        </v-col>
        <v-col class="col-12 col-sm-8 text-left order-0 order-sm-1">
          <h2>Phase IV - Sound Sculptures</h2>
          <p class="pt-5">
            Save your right to mint a one of a kind
            <span class="font-weight-black">NFTONE</span> Sound Sculpture. Based
            on the hexcode of each
            <span class="font-weight-black">NFTONE</span>, unique sounds will be
            created by an audio software and algorithm and then be transformed
            into an abstract 3D sculpture generated from its waveform.
          </p>
        </v-col>
      </v-row>

      <v-row class="mlr-0 no-gutters mt-10 mt-md-0 align-center">
        <v-col class="col-12 col-sm-8 text-left">
          <h2>Phase V - NFTONE Sneaker</h2>
          <p class="pt-5 pr-5">
            Owning a <span class="font-weight-black">NFTONE</span> will give you
            the exclusive right to purchase a unique pair of sneakers containing
            your individual color and hexcode. A limited edition of 10k
            pairs,<br />
            one pair for each <span class="font-weight-black">NFTONE</span> -
            exclusively designed, developed and manufactured by<br />
            <a
              href="https://www.footwearstudios.com/"
              target="_blank"
              class="a__black"
              >Footwear Studios</a
            >
            and
            <a
              href="https://www.instagram.com/adrienwira_design/"
              target="_blank"
              class="a__black"
              >AW Design</a
            >
            (Adidas, Balmain a.o.). More will be announced soon!
          </p>
        </v-col>
        <v-col class="col-12 col-sm-4 pt-16 pt-md-0 mt-n16 mt-md-0">
          <img
            src="@/assets/episodes/nftone_shoe.gif"
            alt="NFTONE Sneaker"
            class="width-100"
          />
        </v-col>
      </v-row>

      <v-row class="mlr-0 no-gutters mt-5 mt-md-16 align-center">
        <v-col class="col-12 text-left d-flex d-md-none">
          <h2>Phase VI - Metaverse</h2>
        </v-col>
        <v-col class="d-flex justify-center col-12 col-md-4">
          <SplineAnimation></SplineAnimation>
        </v-col>
        <v-col class="col-12 text-left d-flex d-md-none">
          <p class="pt-5">
            Imagine the color grid of all unique 10.000
            <span class="font-weight-black">NFTONES</span> as a floorplan for a
            metaverse. A digital space where each
            <span class="font-weight-black">NFTONE</span> represents one parcel.
            You will be able to use it as a digital exhibition space to show off
            your owned NFT art pieces or rent it out to other artists. The
            possibilities are endless.
          </p>
        </v-col>
        <v-col class="col-12 col-md-8 text-left d-none d-md-block">
          <h2>Phase VI - Metaverse</h2>
          <p class="pt-5">
            Imagine the color grid of all unique 10.000
            <span class="font-weight-black">NFTONES</span> as a floorplan for a
            metaverse.<br />
            A digital space where each
            <span class="font-weight-black">NFTONE</span> represents one parcel.
            You will be able to use it as a digital exhibition space to show off
            your owned NFT art pieces or whatever comes to your imagination. The
            possibilities are endless.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <div id="par1" class="parallaxContainer">
      <div id="par2" class="parallax"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Phases",
    components: {
      SplineAnimation: () => import("../SplineAnimation.vue"),
    },
    data() {
      return {
        hovered: false,
        overlay: false,
        publicPath: process.env.BASE_URL,
      };
    },
  };
  /* eslint-disable no-unused-vars */
  document.addEventListener("scroll", function (e) {
    window.requestAnimationFrame(scrollStepFun);
  });

  function scrollStepFun() {
    let pel = document.getElementById("par1");
    let el = document.getElementById("par2");
    let rect = pel.getBoundingClientRect();

    let rectCenter = rect.height / 2 + rect.top;
    let initialNoop = window.innerHeight * 0.9;
    let animationDone = 0.05 * window.innerHeight;
    let maxDegreeRotation = 126;
    let minTransparency = 5;
    let initDegreeRotation = 65;
    let initwhitePercent = 40;

    let deg = initDegreeRotation;
    let whitePercent = initwhitePercent;
    if (rectCenter < animationDone) {
      deg = maxDegreeRotation;
      whitePercent = minTransparency;
    } else if (rectCenter < initialNoop) {
      deg =
        initDegreeRotation +
        ((maxDegreeRotation - initDegreeRotation) * (rectCenter - initialNoop)) /
          (animationDone - initialNoop);
      if (deg > 45) {
        let deg2 = deg;
        if (deg2 > 90) {
          deg2 = 90 - (deg2 - 90);
        }
        whitePercent =
          minTransparency +
          (initwhitePercent - minTransparency) * (1 - (deg2 - 45) / 45);
      }
    }
    //
    el.style.setProperty("--whitePercent", whitePercent + "%");
    el.style.setProperty("--deg", deg + "deg");
  }
  /* eslint-disable no-unused-vars */
</script>

<style>
span.cc-link {
  text-decoration: underline;
  cursor: pointer;
}
.spline-container2 {
  width: 100%;
  height: 320px;
  position: relative;
}

.spline-container2 > iframe {
  position: absolute;
  right: 0;
  top: 20px;
}

.spline-container2 > img {
  position: absolute;
  bottom: -32px;
  left: -6px;
  pointer-events: none;
  z-index: 2;
  width: 100%;
}

#spline2Watermark {
  width: 52px;
  height: 52px;
  background: #f4f4f4;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 3;
}

.parallaxContainer {
  pointer-events: none;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  -o-perspective: 1000px;
  perspective: 1000px;
  overflow: hidden;
  padding-bottom: 297px;
  margin-top: -125px;
  padding-top: 0px;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.parallaxContainer .parallax {
  width: 100%;
  height: 240px;
  -webkit-transform: rotateX(var(--deg)) scale(2.5, 2);
  transform: rotateX(var(--deg)) scale(2.5, 2);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) var(--whitePercent),
      #f4f4f4 100%
    ),
    url("~@/assets/episodes/parallax.png");
  background-position-y: bottom;
  transform-origin: bottom;
  background-size: contain;
}

@media (min-width: 400px) {
  .parallaxContainer .parallax {
    height: 538px !important;
  }

  .parallaxContainer {
    padding-bottom: 297px !important;
    margin-top: -249px !important;
  }
}

img.width-100 {
  width: 100%;
}

img.width-75 {
  width: 75%;
}

img.width-60 {
  width: 60%;
}

.width-70 {
  width: 70%;
}

.relative {
  position: relative;
}

#episode4 {
  position: absolute;
  width: 50%;
  transform: rotate(-77deg);
  z-index: 2;
  left: 217px;
  top: -100px;
}

#episode4-1 {
  position: absolute;
  width: 70%;
  left: 40px;
  transform: rotate(-5deg);
  top: -60px;
}

.relative {
  position: relative;
}

img.pointer {
  cursor: pointer;
}

.width-50 {
  width: 50%;
}
</style>