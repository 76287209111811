<template>
  <svg
    @click.stop.prevent="$emit('flip')"
    version="1.1"
    id="nftone_front"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="284.79998779296875 202.39999389648438 430.4000244140625 594.800048828125"
    xml:space="preserve"
    class="modalSVG"
  >
    <g>
      <rect :fill="svgfill" x="284.8" y="202.4" width="430.3" height="430.3" />
      <path
        fill="white"
        d="M715.2,632.7v142.2c0,12.3-10,22.3-22.3,22.3H307.2c-12.3,0-22.3-10-22.3-22.3V632.7H715.2z"
      />
      <text transform="matrix(1 0 0 1 305.1255 694.4856)" class="st2 st3">
        NFTONE
      </text>
      <text transform="matrix(1 0 0 1 306.8794 773.011)" class="st4 st5">
        {{ name }}
      </text>
      <text transform="matrix(1 0 0 1 307.2515 742.2766)" class="st6 st7">
        {{ code }}
      </text>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "ToneFront",
    props: ["name", "code"],
    computed: {
      style() {
        return (
          "background-image: radial-gradient(50% 50% at center, white, " +
          this.code +
          ")"
        );
      },
      svgfill() {
        return this.code;
      },
      nftoneFill() {
        return "background-color: " + this.code;
      },
    },
  };
</script>

<style>
div.outerPopup {
  max-width: 430px;
  max-height: 594px;
  display: flex;
}

#innerTone {
  max-width: 430px;
  height: 100%;
}

.st2 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 900;
}
.st3 {
  font-size: 53.9424px;
}
.st4 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
}
.st5 {
  font-size: 20.2015px;
}
.st6 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
}
.st7 {
  font-size: 33.6708px;
}

.st0 {
  fill: white;
}
</style>