<template>
  <v-container class="layoutContainer max-width">
    <v-row
      class="
        mlr-0
        team-container
        mt-5 mt-md-0
        justify-space-around justify-md-space-between
      "
    >
      <v-col class="col-12 mb-2">
        <h2>Team</h2>
      </v-col>
      <v-col class="col-6 col-md-2">
        <img src="@/assets/colors/nftone_co.png" alt="" />
        <p>Concept & Design</p>
      </v-col>
      <v-col class="col-6 col-md-2">
        <img src="@/assets/colors/nftone_to.png" alt="" />
        <p>Coding</p>
      </v-col>
      <v-col class="col-6 col-md-2">
        <img src="@/assets/colors/nftone_ts.png" alt="" />
        <p>Marketing & Strategy</p>
      </v-col>
      <v-col class="col-6 col-md-2">
        <img src="@/assets/colors/nftone_mg.png" alt="" />
        <p>Coding</p>
      </v-col>
      <v-col class="col-6 col-md-2">
        <img src="@/assets/colors/nftone_sa.png" alt="" />
        <p>
          Investor & Advisor
          <a href="https://cakebox.vc/" target="_blank" class="a__black"
            >(cakebox.vc)</a
          >
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "Team",
  };
</script>

<style>
.team img {
  width: 70%;
}

div .team {
  width: 70%;
  padding-bottom: 70%;
  margin: auto;
}

div.team + p {
  font-weight: 700;
  margin-top: 1em;
}

div .team.team-orange {
  background-color: #ff4f00;
}

div .team.team-mint {
  background-color: #00c5cd;
}

div .team.team-tomato {
  background-color: #ff6347;
}

div .team.team-green {
  background-color: #004953;
}

.team-container img {
  width: 75%;
}

@media (min-width: 400px) {
  .team-container img {
    padding-bottom: 1em;
  }
}
</style>