<template>
  <div>
    <!-- Intro -->
    <Intro></Intro>
    <!-- Button -->
    <contract-button></contract-button>
    <!-- Color Panel -->
    <ColorPanel></ColorPanel>
    <!-- Phases -->
    <Phases></Phases>
    <!-- Timeline -->
    <!-- <Timeline></Timeline> -->
    <!-- FAQ -->
    <!-- <FAQ></FAQ> -->
    <contract-button></contract-button>
    <!-- Team -->
    <Team></Team>
  </div>
</template>

<script>
  import Intro from "./layout/Intro.vue";
  import ContractButton from "./ContractButton.vue";
  import ColorPanel from "./nftones-grid/ColorPanel.vue"
  import Phases from "./layout/Phases.vue";
  import Team from "./layout/Team.vue";
  export default {
    name: "Home",
    components: {
      Team,
      Phases,
      ContractButton,
      ColorPanel,
      Intro,
    },
  };
</script>