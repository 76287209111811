import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../components/Home.vue"
import CIM from "../components/nftones-grid/ColorInfoModal.vue"
import Thanks from "../components/layout/Thanks.vue"

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        name: 'tone',
        path: 'tone/:tokenId',
        component: CIM,

      },
    ],
  },
  {
    path: '/thanks',
    component: Thanks,
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
