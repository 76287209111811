<template>
  <v-app-bar
    fixed
    color="white"
    elevate-on-scroll
    :style="'z-index:9999, padding: 0'"
    flat
    elevation="0"
  >
    <v-container :class="{layoutContainer : !mobile}" class="d-flex justify-space-between max-width">
      <h1 class="text-black text-900">
        <router-link to='/' class="text-black">NFTONE</router-link>
        </h1>
      <div class="d-flex align-center">
        <a href="https://twitter.com/nftonezone" target="_blank">
          <v-icon id="iconTwitter" color="black">mdi mdi-twitter</v-icon>
        </a>
        <div class="mx-2"></div>
        <a href="https://www.instagram.com/nftone.zone/" target="_blank">
          <v-icon id="iconInstagram" color="black">mdi mdi-instagram</v-icon>
        </a>
        <div class="mx-2"></div>
        <a href="https://discord.com/invite/epaTTrzYTB" target="_blank">
          <v-icon id="iconDiscord" color="black">mdi mdi-discord</v-icon>
        </a>
        <!-- removed till sale start
        <div class="mx-2"></div>
        <a href="https://opensea.io" target="_blank" id="opensea-logo">
          <img src="@/assets/opensea.svg" alt="" />
        </a> -->
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
  export default {
    name: "Header",
    computed: {
      mobile: function() {
        return this.$vuetify.breakpoint.width <= 400
      }
    }
  };
</script>

<style>
.v-toolbar__content {
  padding: 0 !important;
}
</style>